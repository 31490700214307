import React from "react";
import { APP_NAME } from "configs/AppConfig";

export default function Footer() {
  return (
    <footer className="footer">
      <span>
        &copy; {`${new Date().getFullYear()}`} UDONCOP by Security Pitch
      </span>
    </footer>
  );
}
