// import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";
// import SideNav from "components/layout-components/SideNav";
// import TopNav from "components/layout-components/TopNav";
// import Loading from "components/shared-components/Loading";
// import MobileNav from "components/layout-components/MobileNav";
// import HeaderNav from "components/layout-components/HeaderNav";
// import PageHeader from "components/layout-components/PageHeader";
// import Footer from "components/layout-components/Footer";
// import AppViews from "views/app-views";
// import { Layout, Grid } from "antd";

// import navigationConfig from "configs/NavigationConfig";
// import {
//   SIDE_NAV_WIDTH,
//   SIDE_NAV_COLLAPSED_WIDTH,
//   NAV_TYPE_SIDE,
//   NAV_TYPE_TOP,
//   DIR_RTL,
//   DIR_LTR,
// } from "constants/ThemeConstant";
// import utils from "utils";
// import { useThemeSwitcher } from "react-css-theme-switcher";
// import { LoadingWrapper } from "../../components/shared-components/LoadingPage";

// const { Content } = Layout;
// const { useBreakpoint } = Grid;

// export const AppLayout = ({
//   navCollapsed,
//   navType,
//   location,
//   direction,
//   hideSidebar,
// }) => {
//   const [loading, setLoading] = useState(true);

//   const currentRouteInfo = utils.getRouteInfo(
//     navigationConfig,
//     location.pathname
//   );
//   const screens = utils.getBreakPoint(useBreakpoint());
//   const isMobile = screens.length === 0 ? false : !screens.includes("lg");
//   const isNavSide = navType === NAV_TYPE_SIDE;
//   const isNavTop = navType === NAV_TYPE_TOP;
//   const getLayoutGutter = () => {
//     if (isNavTop || isMobile) {
//       return 0;
//     }
//     return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
//   };

//   const { status } = useThemeSwitcher();

//   if (status === "loading") {
//     return <Loading cover="page" />;
//   }

//   const getLayoutDirectionGutter = () => {
//     if (direction === DIR_LTR) {
//       return { paddingLeft: getLayoutGutter() };
//     }
//     if (direction === DIR_RTL) {
//       return { paddingRight: getLayoutGutter() };
//     }
//     return { paddingLeft: getLayoutGutter() };
//   };

//   if (loading) {
//     setTimeout(() => {
//       setLoading(false);
//     }, 2000);
//   }

//   if (loading) {
//     return (
//       <LoadingWrapper>
//         <Loading />
//       </LoadingWrapper>
//     );
//   }

//   return (
//     <Layout>
//       <HeaderNav isMobile={isMobile} />
//       {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}
//         <Layout className="app-container">
//           {isNavSide && !isMobile ? (
//             <SideNav routeInfo={currentRouteInfo} />
//           ) : null}
//         </Layout>
//         <Layout className="app-container">
//           {isNavSide && !isMobile ? (
//             <SideNav routeInfo={currentRouteInfo} />
//           ) : null}

//           <Layout className="app-layout" style={getLayoutDirectionGutter()}>
//             <div className={`app-content ${isNavTop ? "layout-top-nav" : ""}`}>
//               <PageHeader
//                 display={currentRouteInfo?.breadcrumb}
//                 title={currentRouteInfo?.title}
//               />
//               <Content>
//                 <AppViews />
//               </Content>
//             </div>
//             <Footer />
//           </Layout>
//         </Layout>
//       {isMobile && <MobileNav />}
//     </Layout>
//   );
// };

// const mapStateToProps = ({ theme }) => {
//   const { navCollapsed, hideSidebar, navType, locale } = theme;
//   return { navCollapsed, hideSidebar, navType, locale };
// };

// export default connect(mapStateToProps)(React.memo(AppLayout));

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SideNav from "components/layout-components/SideNav";
import TopNav from "components/layout-components/TopNav";
import Loading from "components/shared-components/Loading";
import MobileNav from "components/layout-components/MobileNav";
import HeaderNav from "components/landding-page-component/HeaderNav";
import PageHeader from "components/layout-components/PageHeader";
import Footer from "components/layout-components/Footer";
import AppViews from "views/app-views";
import { Layout, Grid } from "antd";
import IconCustom from "@ant-design/icons";
import navigationConfig from "configs/NavigationConfig";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR,
} from "constants/ThemeConstant";
import utils from "utils";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { LoadingWrapper } from "../../components/shared-components/LoadingPage";
import { FloatingTools } from "components/shared-components/FloatingTools";
import { FacebookFilled, PhoneFilled } from "@ant-design/icons";
import { lineIcon } from "assets/svg/iconantdcustom";
import styled from "styled-components";
const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({
  navCollapsed,
  navType,
  location,
  direction,
  hideSidebar,
  AccessToken = "",
}) => {
  const [loading, setLoading] = useState(true);

  const currentRouteInfo = utils.getRouteInfo(
    navigationConfig,
    location.pathname
  );
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes("lg");
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const { status } = useThemeSwitcher();

  // if (status === "loading") {
  //
  // }
  if (loading) {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }

  if (loading) {
    return <Loading cover="page" />;
  }

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return { paddingLeft: getLayoutGutter() };
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() };
    }
    return { paddingLeft: getLayoutGutter() };
  };

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} AccessToken={AccessToken} />
      <Layout className="app-container ">
        <Layout className="app-layout">
          <AppChangeBackGroundTheme
            className={`app-content ${isNavTop ? "layout-top-nav" : ""} `}
          >
            <PageHeader
              display={currentRouteInfo?.breadcrumb}
              title={currentRouteInfo?.title}
            />
            <Content className="content-full">
              <AppViews />
            </Content>
          </AppChangeBackGroundTheme>
          <img
            src={`/img/new-logo.png`}
            alt=""
            style={{
              width: "10%",
              margin: "0px 0px 10px 1.8%",
            }}
          />
          <Footer />
        </Layout>
      </Layout>
      {/* <FloatingTools>
        <a
          href="https://www.facebook.com/profile.php?id=100064852773936"
          class="float-facebook no-underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookFilled />
        </a>
        <a
          href="tel:042 221 077"
          class="float-line no-underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconCustom component={lineIcon} />
        </a>
        <a
          href="tel:042-221-077"
          class="float-twitter no-underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          <PhoneFilled />
        </a>
      </FloatingTools> */}
    </Layout>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { navCollapsed, hideSidebar, navType, locale } = theme;
  const { AccessToken = "" } = auth;
  return { navCollapsed, hideSidebar, navType, locale, AccessToken };
};

export default connect(mapStateToProps)(React.memo(AppLayout));

const AppChangeBackGroundTheme = styled.div`
  &::before {
    content: "";
    background-image: url(/img/background-default-light-logo.png);
    background-size: 100% 100%;
    display: inline-block;
    height: 330.195px;
    width: 332px;
    position: absolute;
    bottom: 50px;
    right: 25px;
    z-index: -1000;
  }
`;
