import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Layout } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
  MenuOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import Logo from "./Logo";
import NavNotification from "./NavNotification";
import NavProfile from "./NavProfile";
import NavLanguage from "./NavLanguage";
import NavPanel from "./NavPanel";
import NavSearch from "./NavSearch";
import SearchInput from "./NavSearch/SearchInput.js";
import { toggleCollapsedNav, onMobileNavToggle } from "redux/actions/Theme";
import { signOut } from "redux/actions/Auth";
import { useHistory, useParams } from "react-router-dom";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import utils from "utils";
import LogoCustom from "./LogoCustom";
import Swal from "sweetalert2";

const { Header } = Layout;

export const HeaderNav = (props) => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    currentTheme,
    direction,
    AccessToken = "",
    signOut,
    Username, first_name, last_name
  } = props;
  const [searchActive, setSearchActive] = useState(false);

  const onSearchActive = () => {
    setSearchActive(true);
  };

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };
  const history = useHistory();
  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(
        currentTheme === "dark" ? "#00000" : "#ffffff"
      );
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  useEffect(() => {
    if (!isMobile) {
      onSearchClose();
    }
  });
  useEffect(() => {
    console.log(props);
  }, [props]);

  const onSignOut = () => {
    Swal.fire({
      title: "คุณต้องการออกจากระบบ",
      text: "",
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ยืนยัน",
      confirmButtonColor: "#FF3744",
      showCancelButton: true,
      icon: "warning",
    }).then((res) => {
      if (res.isConfirmed) {
        localStorage.clear()
        window.location.href = "/"
      }
    });
  }

  return (
    <Header
      className={`app-header ${navMode}`}
      style={{ backgroundColor: headerNavColor }}
    >
      {/* <div
        className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""} `}
      >
        <LogoCustom logoType={navMode} />

        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-right d-flex align-items-center">
           
          </div>
        </div>
      </div> */}
      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        <Logo logoType={navMode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-right d-flex align-items-center">
            {!isMobile && (
              <>
                {!AccessToken && (
                  <>
                    <Button
                      type="link"
                      className="mr-2"
                      onClick={() => history.push("/auth/login")}
                    >
                      เข้าสู่ระบบ
                    </Button>
                    <Button type="primary" onClick={() => history.push("/auth/register")} >สมัครเข้าใช้งาน</Button>
                  </>
                )}
                {AccessToken && (
                  <>
                    <label>{[first_name, last_name].join(" ")}</label>
                    <Button
                      type="link"
                      onClick={onSignOut}
                    >
                      <LogoutOutlined /> ออกจากระบบ
                    </Button>
                  </>
                )}
              </>
            )}
            {isMobile && <MenuOutlined className=" font-size-lg " />}
          </div>
        </div>
      </div>
    </Header>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
  } = theme;

  const { Username, first_name, last_name } = auth

  return {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
    Username, first_name, last_name
  };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
  signOut,
})(HeaderNav);
