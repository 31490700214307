import React from "react";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_TOP,
} from "constants/ThemeConstant";
import { APP_NAME } from "configs/AppConfig";
import { connect } from "react-redux";
import utils from "utils";
import { Grid } from "antd";
import { useHistory } from "react-router-dom";
const { useBreakpoint } = Grid;

const getLogoWidthGutter = (props, isMobile) => {
  const { navCollapsed, navType } = props;

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  // if(isMobile && !props.mobileLogo) {
  //   return 0
  // }
  if (isNavTop) {
    return "auto";
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
  } else {
    return `${SIDE_NAV_WIDTH}px`;
  }
};

const getLogo = (props) => {
  const { navCollapsed, logoType } = props;
  if (logoType === "light") {
    if (navCollapsed) {
      return "/img/logo-sm-white.png";
    }
    return "/img/logo-white.png";
  }

  if (navCollapsed) {
    return "/img/logo-sm.png";
  }
  return "/img/logo-white.png";
};
const sourceLogo = (isMobile) => {
  if (isMobile) {
    return "/img/logo-white-small.png";
  }
  return "/img/logo-white.png";
};
const getLogoDisplay = (isMobile, mobileLogo) => {
  if (isMobile && !mobileLogo) {
    return "d-none";
  } else {
    return "logo";
  }
};

export const Logo = (props) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const history = useHistory()
  return (
    <div
      // className={getLogoDisplay(isMobile, props.mobileLogo)}
      className="logo "
      style={{ width: `${getLogoWidthGutter(props, isMobile)}` }}
    >
      <img
        src={sourceLogo(isMobile)}
        alt={`${APP_NAME} logo`}
        className="h-75 cursor-pointer"
        onClick={() => {
          history.push("/app/home");
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType } = theme;
  return { navCollapsed, navType };
};

export default connect(mapStateToProps)(Logo);
