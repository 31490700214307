import axios from "axios";
import {
  AUTH_BASE_URL,
  ONE_FORCE_WEBFORM_BASE_URL,
  ONE_FORCE_WEBFORM_AUTH,
} from "configs/AppConfig";
const SITE_MODE = "native";
const ISEQUAL = {
  only_dev: "development",
  only_prod: "production",
  only_stg: "staging",
  native: process.env.REACT_APP_SERV,
};
const envUpload = {
  development: "okimct8lo9",
  production: "okimct8lo9",
};

const upload = axios.create({
  baseURL: `https://${
    envUpload[ISEQUAL[SITE_MODE]]
  }.execute-api.ap-southeast-1.amazonaws.com/v1`,
  timeout: 60000,
});
const service = axios.create({
  baseURL: ONE_FORCE_WEBFORM_BASE_URL,
  timeout: 60000,
});
const new_service_get_portal = axios.create({
  baseURL: "https://zcyjo86dfb.execute-api.ap-southeast-1.amazonaws.com/v1",
  timeout: 60000,
});
const service_auth = axios.create({
  baseURL: ONE_FORCE_WEBFORM_AUTH,
  timeout: 60000,
});
const new_service = axios.create({
  baseURL: "https://zcyjo86dfb.execute-api.ap-southeast-1.amazonaws.com/v1",
  timeout: 60000,
});

const auth_service = axios.create({
  baseURL: AUTH_BASE_URL,
  timeout: 60000,
});

const ocr_service = axios.create({
  baseURL: "https://yvvh6t9fol.execute-api.ap-southeast-1.amazonaws.com/v1",
  timeout: 100000,
});
const list_record_service = axios.create({
  baseURL: "https://rnfvb8is2e.execute-api.ap-southeast-1.amazonaws.com/v1",
  timeout: 100000,
});
const report_service = axios.create({
  baseURL: "https://rnfvb8is2e.execute-api.ap-southeast-1.amazonaws.com/v1",
  timeout: 100000,
});
// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);
new_service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

auth_service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);
ocr_service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);
service_auth.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);
list_record_service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);
report_service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);
upload.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);
new_service_get_portal.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export {
  service,
  auth_service,
  ocr_service,
  new_service,
  service_auth,
  list_record_service,
  report_service,
  upload,
  new_service_get_portal,
};
